.tabHeader {
  padding: 250px;
  &:hover {
    color: inherit;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: blue !important;
  font-weight: bold;
}

.ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: inherit;
}
